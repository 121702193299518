import { useEffect, useState } from "react";
/* Importing Components */
import {
  Map,
  LocationCard,
  SearchLocationBar,
} from "../../../../components/parts";
import { CardWrapper } from "../../../../components/wrapper";
import { useAuth } from "../../../../context/AuthProvider";
/* Importing Stylesheet */
import styles from "./style.module.scss";
import { Loader } from "../../../../components/ui";
import { GET_API_URLS } from "../../../../utils/constant";
import jwtInterceoptor from "../../../../api/jwtInterceptor";

const Locations = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [filteredLocationData, setFilteredLocationData] = useState(false);
  const [totalLocation, setTotalLocation] = useState(0);
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    jwtInterceoptor
      .get(GET_API_URLS.STORE_URL)
      .then((response) => {
        // console.log(response);
        setLoading(false);
        setAuth((prev) => ({ ...prev, stores: response.data }));
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
        console.log("Error", error);
      });
  }, [setAuth]);

  useEffect(() => {
    // jwtInterceoptor
    //   .get(GET_API_URLS.CITIES)
    //   .then((response) => {
    //     setAuth((prev) => ({ ...prev, cities: response.data }));
    //   })
    //   .catch((error) => {
    //     console.log("city Error", error);
    //   });

    jwtInterceoptor
      .get(GET_API_URLS.PROVINCE)
      .then((response) => {
        setAuth((prev) => ({ ...prev, province: response.data }));
      })
      .catch((error) => {
        console.log("city Error", error);
      });
  }, [setAuth]);

  useEffect(() => {
    if (auth.stores) {
      setTotalLocation(auth.stores.length);
    }
  }, [auth.stores, setTotalLocation]);

  // console.log('stores', auth.stores)

  return (
    <div className={styles.locationsPageContainer}>
      <CardWrapper className={styles.mapBlock}>
        <Map
          locationData={
            loading
              ? null
              : error
              ? "There was a problem while loading the data"
              : auth.stores
          }
          error={error}
        />
      </CardWrapper>
      {auth.stores && (
        <CardWrapper className={styles.locationSearchSection}>
          <div>
            <h2>Search Location</h2>
            <SearchLocationBar
              locations={auth.stores}
              filteredData={(data) => {
                setFilteredLocationData(data);
              }}
            />
          </div>
          <div>
            <h2>Total Location</h2>
            <h2>{totalLocation}</h2>
          </div>
        </CardWrapper>
      )}
      <div className={styles.locationBlock}>
        {loading ? (
          <Loader />
        ) : error ? (
          <h3>There was a problem while loading the data.</h3>
        ) : (
          filteredLocationData &&
          filteredLocationData.map((store, i) => {
            return (
              <LocationCard
                storeId={store.id}
                storeName={store.name}
                businessType={store.business_type.name}
                storeImg={store.image}
                address={store.address}
                longitude={store.longitude}
                latitude={store.latitude}
                amenities={store.amenities}
                is24by7={store.is24by7}
                timings={store.timings}
                storeData={store}
                key={i}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default Locations;
